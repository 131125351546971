.search-nav {
    .search_input {
        background: '#ffffff';
        box-shadow: -12px -12px 20px 0 rgba(255, 255, 255, 0.5), 12px 12px 20px 0 RGBA(209, 205, 199, 0.5);
    }

    .search-message p,
    .cooking-method-description p {
        margin: 0;
    }

    .MuiAccordionSummary-content {
        margin-top: 16px !important;
        margin-bottom: 0 !important;
    }

    .MuiPaper-root.MuiAccordion-root.Mui-expanded::before {
        opacity: 1 !important;
    }
}