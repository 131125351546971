.section {
  &-title {
    font-family: var(--header-font), sans-serif !important;
    font-weight: 500;
    font-size: 16px !important;
    background: #fff;
    color: #000;
    margin-top: 32px;
    margin-bottom: 16px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
    padding: 5px;
  }
}