.active {
    background: #f2f4fd;
    color: #1437e2;
    border: 1px solid #a4b0ee;
}
.inactive {
    background: #f4f4f4;
    color: #7a7a7a;
}

.tab_button {
    box-shadow: none;
    &:hover {
        background: #f2f4fd;
        color: #1437e2;
    }
}