.card-image {
    width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: contain;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}