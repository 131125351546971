.list {
  &-icon {
    background-color: #000 !important;
  }

  &-input-adornment {
    background: linear-gradient(rgb(71, 71, 71), rgb(71, 71, 71)) !important;

    font-family: var(--header-font), sans-serif !important;
    font-weight: 300;
    font-size: 24px !important;
    text-transform: uppercase;
    color: #fff;
    padding: 5px;
  }

  &-title {
    font-family: var(--header-font), sans-serif !important;
    font-weight: 300;
    font-size: 39px !important;
    text-transform: uppercase;
    color: var(--brand-color);
  }

  &-label {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 16px;
    color: rgb(9, 9, 9);
    margin: 16px 0 !important;
  }

  &-input {
    background: linear-gradient(rgb(255, 255, 255), rgb(246, 246, 241)) !important;
    border: 1px solid #000 !important; 
    padding: 14px 16px !important;
    font-weight: 200 !important;
    box-shadow: 4px 4px 32px 0 rgb(224, 224, 224), -16px -16px 32px 0 rgb(255, 255, 255);
  }

  &-button {
    background:  var(--button-background-color) !important;
    font-family: var(--button-font), sans-serif !important;
    text-transform: uppercase !important;
    font-weight: 200 !important;
    font-size: 22px !important;
    line-height: 22px !important;
    color: rgb(255, 255, 255) !important;
    padding-top: 25px !important;
    padding-bottom: 25px !important;
    border-radius: 0 !important;
  }
}