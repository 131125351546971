.nutrition-tag {
  background-color: #000 !important;
  color: #fff !important;
  font-family: var(--nutrition-font) !important;
  text-transform: uppercase !important;
  border-radius: 0 !important;
}

.nutrition-header {
  font-size: 2rem;
  color: #010101;
  text-transform: uppercase;
  font-weight: bold;

    @media (max-width: 480px) {
      font-size: 1rem !important;
    }
}

.table {
  display: flex;
  flex-direction: column;
  padding: 5px;
  color: #000;
  border: 4px solid #000;

  th, td {
    color: #000;
  }

  &_title {
    font-family: var(--nutrition-font);
    font-size: 32px;
    font-weight: bold;
    width: 100%;
  }

  &_serving {
    font-family: var(--nutrition-font);
    font-size: 14px;
    width: 100%;

    &-size {
      font-family: var(--nutrition-font);
      font-size: 15px;
      width: 100%;
    }
  }

  &_block {
    &-calories {
      display: inline-block;
      border-bottom: 5px solid #000;

      &-left {
        display: flex;
        flex-direction: column;
      }

      &-right {
        font-family: var(--nutrition-font);
        font-weight: 800;
        display: flex;
        align-items: end;
        font-size: 40px;
      }

      &-serving {
        font-family: var(--nutrition-font);
        font-size: 16px;
        width: 100%;
      }

      &-text {
        font-family: var(--nutrition-font);
        font-size: 26px;
        font-weight: 800;
        width: 100%;
      }
    }

    &-fat {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
      margin-top: 8px;

      &-left {
        display: flex;
        flex-direction: row;
      }
    }

    &-saturated {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
      margin-top: 8px;

      &-left {
        display: flex;
        flex-direction: row;
      }
    }

    &-trans {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
      margin-top: 8px;

      &-left {
        display: flex;
        flex-direction: row;
      }
    }

    &-cholesterol {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
      margin-top: 8px;

      &-left {
        display: flex;
        flex-direction: row;
      }
    }

    &-fiber {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
      margin-top: 8px;

      &-left {
        display: flex;
        flex-direction: row;
      }
    }

    &-sugar {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      width: 100%;
      margin-top: 8px;

      &-left {
        display: flex;
        flex-direction: row;
        padding-left: 13px;
        padding-bottom: 3px;
      }

      &-border {
        border-bottom: 2px solid #000;
        margin-left: 30px;
      }
    }

    &-includes {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
      margin-top: 8px;

      &-left {
        display: flex;
        flex-direction: row;
      }
    }

    &-protein {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding-bottom: 3px;
      margin-top: 8px;
    }

    &-vitamin {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
      margin-top: 8px;

      &-left {
        display: flex;
        flex-direction: row;
      }
    }

    &-vitaminB {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
      border-bottom: 7px solid #000;
      padding-bottom: 3px;
      margin-top: 8px;

      &-left {
        display: flex;
        flex-direction: row;
      }
    }

    &-footer {
      width: 100%;
      font-size: small;

      &-text {
        font-family: var(--nutrition-font);
        padding-right: 8px;
      }
    }
  }

  &_daily {
    display: flex;
    justify-content: end;
    margin-top: 8px;
    width: 100%;
  }
}

.pre-space {
  white-space: pre;
}

.border-btm-slim {
  border-bottom: 3px solid #000;
}

.border-btm-bold {
  border-bottom: 5px solid #000;
}

.text-bold {
  font-size: 12px;
  font-weight: 800;
}

.padding {
  padding-left: 13px;
}

.nutrition {
  & .search-message img {
      width: 100%;
      height: auto;
      max-height: 500px;
      object-fit: contain;
  }
}
