.image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    border-radius: 0.5rem;
    transition: all 0.3s ease-in-out;
    &:hover {
        transform: scale(1.05);
    }

    &_container {
        position: relative;
        width: 100%;
        max-width: 20rem;
        height: auto;
        border-radius: 0.5rem;
        overflow: hidden;
    }
}