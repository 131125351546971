.video {
    width: 100%;
    padding-bottom: 56.25%;

    &-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    iframe, video {
      width: 100%;
      height: 100%;
    }
}