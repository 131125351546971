.ql-container {
    font-family: var(--body-font), sans-serif !important;
    font-size: unset;
}

.ql-toolbar {
    &.ql-snow {
        background: #000;
        border: none;

        .ql-stroke {
            stroke: #fff;
        }

        .ql-picker {
            color: #fff;
        }

        .ql-picker-options .ql-picker-item {
            color: #000;
        }
    }
}