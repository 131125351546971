@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Merriweather:wght@200;300;400;500;700&family=Montserrat:wght@200;300;400;700&family=Oswald:wght@200;300;400;500;700&family=Outfit:wght@200;300;400;700&family=Quicksand:wght@300;400;700&family=Roboto:wght@300;400;700&display=swap');

body {
    --brand-color: #BC0808;
    --brand-color-2: #AAAAAA;
    --button-background-color: #BC0808;
    --icon-color: #ffffff;
    --site-background-color: #ffffff;

    --body-font: "Oswald";
    --header-font: "Oswald";
    --button-font: "Oswald";
}

body, html {
  background:  var(--site-background-color) !important;
}

body, html, input, select, label, button, p {
  font-family: var(--body-font), sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--header-font), sans-serif !important;
}

.lazyload-wrapper {
  height: 100%;
}

.drawer {
  &-username {
    font-weight: 300 !important;
    font-size: 24px !important;
    color: #000 !important;
    text-decoration: underline;
  }

  &-sidebar-link {
    font-family: var(--button-font), sans-serif !important;
    font-weight: 200;
    font-size: 14px;
    text-transform: uppercase;
    background: #EFEEEE !important;
    color: #262626;
    padding: 0;
    margin: 24px 0;
    box-shadow: -12px -12px 20px 0 rgba(255, 255, 255, 0.2), 12px 12px 20px 0 RGBA(209, 205, 199, 0.5);
  }
}

.statistic {
  &-tile {
    background: #fff;
    margin: 20px;
    border: 1px solid rgba(209, 205, 199, 0.8);
  }

  &-number {
    font-family: var(--header-font), sans-serif !important;
    font-weight: 300;
    font-size: 64px !important;
    line-height: 64px !important;
    text-transform: uppercase;
    color: #000;
  }

  &-title {
    font-family: var(--header-font), sans-serif !important;
    font-weight: 500;
    font-size: 18px !important;
    text-transform: capitalize;
    color: #000;
  }

  &-period {
    font-family: var(--header-font), sans-serif !important;
    font-weight: 200;
    font-size: 12px !important;
    text-transform: uppercase;
    text-decoration: underline;
    color: #000;
  }

  &-slash {
    font-family: var(--header-font), sans-serif !important;
    font-weight: 200;
    font-size: 12px !important;
    text-transform: uppercase;
    text-decoration: underline;
    color: #000;
  }
}

.form {
  &-icon {
    background-color: #000 !important;
  }

  &-section {
    &-title {
      font-family: var(--header-font), sans-serif !important;
      font-weight: 500;
      font-size: 3rem !important;
      background: #fff;
      color: #000;
      margin-top: 32px;
      margin-bottom: 16px;
      padding: 5px;
    }
  }

  &-input-adornment {
    background: linear-gradient(rgb(71, 71, 71), rgb(71, 71, 71)) !important;

    font-family: var(--header-font), sans-serif !important;
    font-weight: 300;
    font-size: 24px !important;
    text-transform: uppercase;
    color: #fff;
    padding: 5px;
  }

  &-title {
    font-family: var(--header-font), sans-serif !important;
    font-weight: bold !important;
    font-size: 1.5rem !important;
    text-transform: capitalize;
    color: var(--brand-color);
  }

  &-label {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;
    color: #000;
    margin: 1rem 0 !important;
  }

  &-input-description {
    font-size: 0.65rem;
    color: #948c8c;
    margin: 0.25rem 0 !important;
    font-style: italic;
  }

  &-input {
    // background: linear-gradient(rgb(255, 255, 255), rgb(246, 246, 241)) !important;
    border: 1px solid rgba(0, 0, 0, 0.12) !important; 
    padding: 14px 16px !important;
    // box-shadow: 4px 4px 32px 0 rgb(224, 224, 224), -16px -16px 32px 0 rgb(255, 255, 255);

    &.p-0 {
      padding: 0 !important;
      height: 100% !important;
      border: none !important;
      border-radius: 0 !important;
      box-shadow: none !important;
      overflow: hidden;
    }
  }

  &-button {
    background:  var(--button-background-color) !important;
    font-family: var(--button-font), sans-serif !important;
    text-transform: uppercase !important;
    font-weight: bold !important;
    color: rgb(255, 255, 255) !important;
    padding-left: 3rem !important;
    padding-right: 3rem !important;
    border-radius: 0 !important;
  }
}

.MuiInputBase-root.MuiInput-root::before {
  display: none;
}