.header-strip {
    position: relative;
    z-index: 2;
    width: 100%;
    background-color: #6e2525;
    box-shadow: 1px 3px 15px rgba(0, 0, 0, 0.6);
    padding: 5px 0;
    .title {
      font-size: 2rem;
      color: #fff;
      font-weight: 400;
      font-family: "Oswald";
      text-align: center;
      @media (max-width: 480px) {
        font-size: 1rem;
      }
    }
  }

  .barcode-image, .product-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }